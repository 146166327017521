import React, { Component } from "react"

import Layout from "../../components/layout"
import GatedContent from "../../components/gatedcontent"

import {CopyTextbox} from '../../components/copybox/'
import "../../styles/copybox.css"

export default class RemoteGatsbyDevelopIndex extends Component {
	render() {
		return (
			<Layout showtitle={true} showtitleback={true} activePath='guide' fullPath='guide/remote-gatsby-develop' pageTitle="Remote Gatsby Develop" privatePage={true}>
				<GatedContent>
					<div className={"margin-content"}>
						Below is a guide on how to access output of <strong>gatsby develop</strong> command remotely.
						<h2>Command</h2>
						Run <strong>gatsby develop</strong> with parameter/option '-H 0.0.0.0'

			  			<CopyTextbox copytext="gatsby develop -H 0.0.0.0" />

						Check output to see if there are problems.
						<h2>Access</h2>

						Your site should be accessible using the address provided like you would access it normally
						<ul>
							<li>http://&lt;IP or URL&gt;:&lt;port #&gt;</li>
							<li>http://&lt;IP or URL&gt;:&lt;port #&gt;/__grapql</li>
							<li>Examples
								<ul>
									<li>http://mytestsite.com:8000</li>
									<li>http://mytestsite.com:8000/__grapql</li>
								</ul>
							</li>
						</ul>
					</div>
				</GatedContent>
			</Layout>
		)
	}
}



