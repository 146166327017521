import React from 'react';


import "./style.css"


export class CopyTextbox extends React.Component {

  constructor(props) {
    super(props);
    this.state = { copySuccess: '(Click to copy)' }
  }

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand('copy');
    this.textArea.value = '';
    this.setState({ copySuccess: '(Copied)' });
  	setTimeout(() => {
  	  this.setState({copySuccess: '(Click to copy)'});
  	}, 2000)
  };

  render() {
	const copydata = this.props.copytext
    return (
      <div>
          <input className={"copybox-textbox font-size-regular"} onClick={this.copyToClipboard}
            ref={(textarea) => this.textArea = textarea}
            value={copydata}
          />
		  <div className={"copybox-note font-size-small"}>{this.state.copySuccess}</div>
      </div>
    );
  }

}

export class CopySpan extends React.Component {

  constructor(props) {
    super(props);
    this.state = { copySuccess: '(Click to copy)' }
  }

  copyToClipboard = (e) => {
  	var tmpval = this.textArea.value;
    this.textArea.select();
    document.execCommand('copy');
    this.textArea.value = tmpval;
    this.setState({ copySuccess: '(Copied)' });
  	setTimeout(() => {
  	  this.setState({copySuccess: '(Click to copy)'});
  	}, 2000)
  };

  render() {
	const copydata = this.props.copytext
    return (
	  <React.Fragment>
	    <input className={"copybox-span font-size-regular"} onClick={this.copyToClipboard}
	  	ref={(textarea) => this.textArea = textarea}
	  	value={copydata}
	    />
	    <span className={"copybox-note font-size-small"}>{this.state.copySuccess}</span>
	  </React.Fragment>
    );
  }

}

